<template src="./NewOffer.html"> </template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import jobsService from "@/services/Jobs";
import InputCurrency from "../../components/InputCurrency/InputCurrency";
import categoryService from "@/services/Category";

export default {
  name: "NewOffer",
  data() {
    return {
      token: "",
      password: "",
      password_verify: "",
      recoverState: "waiting",
      loading: false,
      name: null,
      location: null,
      salary: null,
      typeOfContract: null,
      maxDate: null,
      maxApplicants: null,
      companyDescription: null,
      jobDescription: null,
      responsibilities: null,
      academicRequirements: null,
      category: null,
      subcategory: null,
      background: null,
      technologicalTools: null,
      optionsMaxApplicants: [20, 40, 60, 80, 100, 120, 140, 150],
      radio: 150,
      step: -1,
      categoryOptions: [],
      subcategoryOptions: []
    };
  },
  components: {
    InputCurrency
  },
  validations: {
    name: { required },
    location: { required },
    salary: { number: true },
    typeOfContract: {},
    maxDate: {},
    maxApplicants: {},
    companyDescription: {},
    jobDescription: { required },
    responsibilities: { required },
    academicRequirements: { required },
    category: { required },
    subcategory: { required },
    background: { required },
    technologicalTools: { required }
  },
  mounted() {
    this.getCategories();
    this.fetchJobApplicationPhases();
  },
  updated() {
    if (this.category) {
      const filterCategory = this.categoryOptions.find(element => {
        if (element.id === this.category) {
          return element;
        }
      });
      this.subcategoryOptions = filterCategory.subcategory;
    }
  },
  methods: {
    async getCategories() {
      try {
        this.categoryOptions = await categoryService.getCategoriesWhitSubcategory();
      } catch (e) {
        console.error("error", e);
      }
    },
    async createNewOffer() {
      try {
        this.loading = true;
        if (!this.name) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta un nombre",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.location) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta un lugar",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.responsibilities) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace faltan las responsabilidades",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.background) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta la experiencia requerida",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.maxDate) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta una fecha de cierre",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.maxApplicants) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta un maximo de aplicantes",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.academicRequirements) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta un Requisitos Académicos",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.category) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta una categoría",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.subcategory) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta una sub categoría",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        if (!this.technologicalTools) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Hace falta el manejo de herramientas tecnológicas",
            position: "is-bottom",
            type: "is-danger"
          });
          return false;
        }
        const data = {
          name: this.name,
          location: this.location,
          salary: this.salary,
          jobType: this.typeOfContract,
          description: this.jobDescription,
          responsibilities: this.responsibilities,
          experience: this.background,
          deadline: this.maxDate,
          maxNumberOfParticipants: this.maxApplicants,
          companyDescription: this.companyDescription,
          requirements: this.academicRequirements,
          categoryId: this.category,
          subcategoryId: this.subcategory,
          technologicalTools: this.technologicalTools
        };
        Object.keys(data).forEach(function(key) {
          if (data[key] === null || data[key] === "") delete data[key];
        });
        const response = await jobsService.createJob(data);
        this.$router.push({ name: "ViewOffer", params: { id: response.id } });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Oferta creada correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (e) {
        if(e.response.data.message === 'Invalid request payload input'){
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error en la información de los campos',
            position: "is-bottom",
            type: "is-danger"
          });
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.response.data.message,
            position: "is-bottom",
            type: "is-danger"
          });
        }
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      fetchJobApplicationPhases: "fetchJobApplicationPhases"
    })
  },
  computed: {
    ...mapGetters({
      getJobApplicationPhases: "getJobApplicationPhases"
    })
  }
};
</script>

<style scoped lang="scss" src="./NewOffer.scss"/>
